import { Subscribable } from 'rxjs';
import Parameters from '@/app/connector/Parameters';
import HttpClient from '@/app/connector/HttpClient';
import Path from '@/app/connector/Path';
import ReadOnlyProduct from '@/components/itgit/model/ReadOnlyProduct';
import { ProductRepository } from './ProductRepository';
import Paths from './Paths';
import RequestResponse from './RequestResponse';
import ApiResponse from './ApiResponse';

// class ApiClient implements ProductRepository<any, any> {
class ProductClient
implements ProductRepository<Subscribable<unknown>, ReadOnlyProduct, Parameters> {
  protected httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public listProducts$(parameters: Parameters):
  Subscribable<RequestResponse<ApiResponse<ReadOnlyProduct[], unknown>>> {
    return this.httpClient.get(
      Path.fromArray([Paths.PRODUCTS]),
      parameters,
    );
  }

  public addProduct$(product: any): Subscribable<unknown> {
    return this.httpClient.post(Path.fromArray([Paths.PRODUCTS]), product);
  }
}
export default ProductClient;
