/* eslint-disable no-underscore-dangle */
import { Value } from './Value';

export default class Pagination implements Value {
  private _start: number;

  private _limit: number;

  private constructor(start: number, limit: number) {
    this._start = start;
    this._limit = limit;
  }

  // public value(): unknown {
  //   return this._value;
  // }

  public toString(): string {
    return `{"start":${this._start},"limit":${this._limit}}`;
  }

  public static createFromString(start: number, limit: number): Pagination {
    return new this(start, limit);
  }
}
