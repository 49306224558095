export default class Path {
  private parts: Array<string> = [];

  private constructor(parts: Array<string>) {
    this.parts = parts;
  }

  public static fromArray(parts: Array<string>): Path {
    return new Path(parts);
  }

  public toString(): string {
    return this.parts.join('/');
  }
}
