import { createApp } from 'vue';
import PrimeVue from 'primevue/config'; // https://www.primefaces.org/primevue/
import Column from 'primevue/column';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import DataView from 'primevue/dataview';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'; // https://github.com/primefaces/primevue-typescript-quickstart/blob/master/src/main.ts
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.css';
import 'primeicons/primeicons.css';
import * as ApiClient from '@/components/itgit/api-client-factories';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
// import store from './store';
import { store, key } from './core/store';

const app = createApp(App);

/**
 * How do pass in env base urls
 */

// const instance = Axios.create({
//   baseURL: 'http://localhost',
//   timeout: 1000,
//   headers: { 'X-Custom-Header': 'foobar' },
// });
// const httpClient = new AxiosObservableHttpClient(instance, 'http://localhost:8001');
// this.apiClient = new ApiClient(httpClient);

// https://purple.telstra.com.au/blog/dependency-injection-in-vuejs-app-with-typescript
// https://github.com/inversify/InversifyJS

// const itgitAxios = new AxiosObservableHttpClient(instance, 'http://localhost');
// https://github.com/vue-ioc/vue-ioc
app.use(store);
app.use(router);
app.use(store, key);
app.use(PrimeVue);
// app.use('apiClient', ApiClient);
// app.use(ApiClientPlugin);
app.provide('apiClient', ApiClient);
app.component('Button', Button);
app.component('Column', Column);
app.component('Menu', Menu);
app.component('Menubar', Menubar); // https://primefaces.org/primevue/showcase/#/menubar
app.component('InputText', InputText);
app.component('DataView', DataView);
app.mount('#app');
