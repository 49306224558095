import { Subscribable } from 'rxjs';
import AxiosObservable from 'axios-observable';
import { AxiosRequestConfig } from 'axios';
import { Methods } from '../Methods';
import HttpClient from '../HttpClient';
import { Functions, Handler } from '../Handler';
import Path from '../Path';
import Parameters from '../Parameters';

export default class AxiosObservableHttpClient implements HttpClient {
  private middlewares: Array<Handler> = [];

  private axios: AxiosObservable;

  private baseUrl: string;

  constructor(
    axios: AxiosObservable,
    baseUrl: string,
  ) {
    this.axios = axios;
    // this.axios.defaults.headers.common['Content-Type'] = 'application/vnd.micro+json;version=1';
    // this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    this.baseUrl = baseUrl;
  }

  head(path: Path): Subscribable<unknown> {
    const url = `${this.baseUrl}/${path.toString()}`;
    const config = {
      url: `${this.baseUrl}/${path}`,
      headers: {
        'Content-Type': 'application/vnd.micro+json;version=1',
        // 'Access-Control-Allow-Origin': '*',
      },
    } as AxiosRequestConfig;
    this.handleMiddlewares('head');
    return this.axios.head(url, config);
  }

  get(
    path: Path,
    parameters?: Parameters,
  ): Subscribable<unknown> {
    const url = `${this.baseUrl}${path.toString()}${parameters?.toString()}`;
    const config = {
      url: `${this.baseUrl}${path}`,
      headers: {
        'Content-Type': 'application/vnd.micro+json;version=1',
        // 'Access-Control-Allow-Origin': '*',
      },
      data: {},
      params: {},
    } as AxiosRequestConfig;
    // this.handleMiddlewares('get');
    return this.axios.get(url, config);
  }

  put(
    path: Path,
    body: string | Array<unknown> | unknown,
  ): Subscribable<unknown> {
    const url = `${this.baseUrl}${path.toString()}`;
    const config = {
      url,
    } as AxiosRequestConfig;
    this.handleMiddlewares('put');
    return this.axios.put(url, body, config);
  }

  patch(
    path: Path,
    body: string | Array<unknown> | unknown,
  ): Subscribable<unknown> {
    const url = `${this.baseUrl}${path}`;
    const config = {
      url,
    } as AxiosRequestConfig;
    this.handleMiddlewares('patch');
    return this.axios.patch(url, body, config);
  }

  post(
    path: Path,
    body: string | Array<unknown> | unknown,
  ): Subscribable<unknown> {
    const url = `${this.baseUrl}${path.toString()}`;
    const config = {
      url,
    } as AxiosRequestConfig;
    this.handleMiddlewares('post');
    return this.axios.post(url, body, config);
  }

  delete(path: Path): Subscribable<unknown> {
    const url = `${this.baseUrl}${path.toString()}`;
    const config = {
      url,
    } as AxiosRequestConfig;
    this.handleMiddlewares('delete');
    return this.axios.post(url, config);
  }

  addMiddleware(handler: Handler): void {
    this.middlewares.push(handler);
  }

  // setAuth(authHeaderValue: string): void {
  //   throw new Error('setAuth not implemented yet');
  // }

  protected handleMiddlewares(method: Methods) {
    const handlers = this.middlewares;
    let func: Functions;
    // eslint-disable-next-line default-case
    switch (method) {
      case 'get':
        func = 'onGet';
        break;
      case 'post':
        func = 'onPost';
        break;
      case 'patch':
        func = 'onPatch';
        break;
      case 'put':
        func = 'onPut';
        break;
      case 'delete':
        func = 'onDelete';
        break;
    }
    handlers.forEach((handler) => {
      handler[func]();
    });
  }
}
