// import Vue from 'vue'
// import Vuex from 'vuex'
// // import cart from './modules/cart'
// // import products from './modules/products'
// // import createLogger from '../../../src/plugins/logger'

// // Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

// export default new Vuex.Store({
//   modules: {
//     // cart,
//     // products
//   },
//   strict: debug,
// //   plugins: debug ? [createLogger()] : []
// })

import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';

// define your typings for the store state
export interface State {
  count: number;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol('core');

export const store = createStore<State>({
  state: {
    count: 0,
  },
});
