
import { Options, Vue } from 'vue-class-component';
// https://github.com/primefaces/primevue-typescript-quickstart/blob/master/src/App.vue
// https://github.com/primefaces/primevue
@Options({
})
export default class App extends Vue {
  public message = '';

  public text = '';

  public greet() {
    if (this.text.length) {
      this.message = `Hello ${this.text}`;
    }
  }
}
