import Parameter from '@/app/connector/Parameter';

export default class Parameters {
  private parameters: Parameter[];

  private constructor(parameters: Parameter[]) {
    this.parameters = parameters;
  }

  public addParameter(parameter: Parameter) {
    this.parameters.push(parameter);
  }

  public static fromArray(parameters: Parameter[]) {
    return new this(parameters);
  }

  public toString() {
    let paramSimple = '';
    this.parameters.forEach((param, index) => {
      if (index === 0) {
        paramSimple += `${param.toString()}`;
      } else {
        paramSimple += `&${param.toString()}`;
      }
    });
    if (paramSimple.length > 0) {
      return `?${paramSimple}`;
    }
    return '';
    // return '?'+this.parameters.join('&');
  }
}
