import { Value } from '@/components/itgit/restful-query/Value';

export default class Parameter {
  private entity: string;

  private value: Value;

  private constructor(entity: string, value: Value) {
    this.entity = entity;
    this.value = value;
  }

  public static fromMixed(entity: string, value: Value) {
    return new this(entity, value);
  }

  public toString() {
    // const params = new URLSearchParams();
    // params.set(this.entity, JSON.stringify(this.value));
    // return params;
    // return `${this.entity}=${decodeURI(JSON.stringify(this.value.toString()))}`;
    // return `${this.entity}=${JSON.stringify(this.value.toString())}`;
    return `${this.entity}=${this.value.toString()}`;
  }
}
