/* eslint-disable no-underscore-dangle */
import { Comparison } from './Comparison';

export default class Contains implements Comparison {
  private _comparison = 'co';

  private _value: unknown;

  private constructor(value: unknown) {
    this._value = value;
  }

  public value(): unknown {
    return this._value;
  }

  public toString(): string {
    return `{"${this._comparison}":"${this._value}"}`;
  }

  // public static fromMixed(entity: string, value: unknown | string | number) {
  //   return new this(entity, value);
  // }

  public static createFromInt(value: number): Contains {
    return new this(value);
  }

  public static createFromString(value: unknown): Contains {
    return new this(value);
  }

  public static createFromArray(list: []): Contains {
    throw new Error(`${Contains.constructor.name} not implemented yet.`);
  }
}
