<template>
  <div class="home">
    <div class="flex flex-row flex-wrap">
      <div class="flex">
        <img class="" width="50"  alt="Vue logo" src="../../assets/gluten-free.pngwing.com.png">
      </div>
      <Jumbo msg="Is there Gluten in This?"/>
    </div>
    <div class="flex flex-row flex-wrap">
      <div class="flex m-b-1">
        <form @submit.prevent="searchProducts">
          <InputText type="text" v-model="productName"/>
          <Button type="submit" label="Submit"/>
        </form>
      </div>
      <div class="flex flex-grow p-md-offset-1">
        <DataView :value="products" :layout="layout" >
          <template #list="products" >
            <div><b>{{products.data.name}}</b></div>
          </template>
          <template #grid="products">
            <div>description: <b>{{products.data.description}}</b></div>
          </template>
        </DataView>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Parameters from '@/app/connector/Parameters';
import Parameter from '@/app/connector/Parameter';
import Pagination from '@/components/itgit/restful-query/Pagination';
import Contains from '@/components/itgit/restful-query/Contains';
import productClient from '@/components/itgit/api-client-factories';
import RequestResponse from '@/components/itgit/RequestResponse';
import ReadOnlyProduct from '@/components/itgit/model/ReadOnlyProduct';
import ApiResponse from '@/components/itgit/ApiResponse';
import Jumbo from './Jumbo.vue';

@Options({
  components: {
    Jumbo,
  },
})
export default class Home extends Vue {
  productName = '';

  products: ReadOnlyProduct[] = [];

  loading = false;

  layout = 'list';

  sortKey = null;

  sortOrder = null;

  sortField = null;

  sortOptions = [
    { label: 'Newest First', value: '!year' },
    { label: 'Oldest First', value: 'year' },
    { label: 'Brand', value: 'brand' },
  ]

  public searchProducts(): void {
    this.loading = true;
    this.products = []; // https://primefaces.org/primevue/showcase/#/dataview

    const parameters = Parameters.fromArray([]);
    const pageParam = Parameter.fromMixed('page', Pagination.createFromString(1, 10));

    parameters.addParameter(pageParam);
    parameters.addParameter(Parameter.fromMixed('name', Contains.createFromString(this.productName)));

    productClient.listProducts$(
      parameters,
    )
      .subscribe((response: RequestResponse<ApiResponse<ReadOnlyProduct[], unknown>>) => {
        this.loading = false;
        this.products = response.data.data;
      });
  }
}
</script>
