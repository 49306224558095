import ProductClient from '@/components/itgit/ProductClient';
import Axios from 'axios-observable';
import AxiosObservableHttpClient from '@/app/connector/port/AxiosObservableHttpClient';
import RequestResponse from '@/components/itgit/RequestResponse';

const instance = Axios.create({
  // baseURL: 'http://localhost',
  timeout: 15000,
  // transformRequest: (r: RequestResponse<unknown, unknown>) => r.data, ),
});
const httpClient = new AxiosObservableHttpClient(instance, 'http://localhost:8001');

export default new ProductClient(httpClient);
